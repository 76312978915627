import { NewsContainer, NewsButton, NewsList } from '../components/NewsHome';

import '../styles/blocks/latestNews.scss';

const LatestNews = ({ data, language, locale }) => {
  if (!data?.length) {
    return null;
  }

  return (
    <div className="Block LatestNews">
      <div className="Block-Container Block-Container-Centered LatestNews-Container">
        <h2 className="LatestNews-Title">
          <span>{locale.title}</span>
        </h2>

        <NewsContainer>
          <NewsList newsList={data} language={language} />

          <NewsButton to={`/${language}/news/`}>{locale.btnAllNews}</NewsButton>
        </NewsContainer>
      </div>
    </div>
  );
};

export default LatestNews;
