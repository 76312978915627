import { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

import dengiruLogo from '../static/dengiru_logo.jpg';

const LANG_MAP = {
  en: 'en_EN',
  ru: 'ru_RU',
  tr: 'tr_TR',
};

const OG_SITE_NAME = 'dengi.ru';
const OG_TYPE = 'website';

const SEO = (props) => {
  const { isHomePage, seo, locale } = props;

  const { pathname } = useLocation();
  const pathWithoutLocale = isHomePage
    ? ''
    : pathname.replace(`/${locale}/`, '');

  const allLocales = Object.keys(LANG_MAP);
  const alternativeLocales = allLocales.filter((item) => item !== locale);

  const localesLinkTags = useMemo(() => {
    return allLocales.map((currentLocale) => {
      return (
        <link
          key={currentLocale}
          rel="alternate"
          href={
            isHomePage
              ? `https://${OG_SITE_NAME}/${currentLocale}`
              : `https://${OG_SITE_NAME}/${currentLocale}/${pathWithoutLocale}`
          }
          hrefLang={currentLocale}
        />
      );
    });
  }, [allLocales, isHomePage, pathWithoutLocale]);
  const alternativeLocalesMetaTags = useMemo(() => {
    return alternativeLocales.map((currentLocale) => {
      return (
        <meta
          content={LANG_MAP[currentLocale]}
          key={currentLocale}
          property="og:locale:alternate"
        />
      );
    });
  }, [alternativeLocales]);

  return (
    <Helmet>
      <title>{seo.title || ''}</title>

      <meta name="description" content={seo.description || ''} />

      <link rel="canonical" href={`https://dengi.ru${pathname}`} />

      {localesLinkTags}

      <link
        rel="alternate"
        hrefLang="x-default"
        href={
          isHomePage
            ? 'https://dengi.ru'
            : `https://dengi.ru/ru/${pathWithoutLocale}`
        }
      />

      <meta property="og:description" content={seo.description} />
      <meta property="og:image" content={`https://dengi.ru${dengiruLogo}`} />
      <meta property="og:image:alt" content="Платежная система dengi.ru" />
      <meta
        property="og:image:secure_url"
        content={`https://dengi.ru${dengiruLogo}`}
      />
      <meta property="og:image:type" content="image/jpeg" />
      <meta property="og:image:height" content="630" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:locale" content={LANG_MAP[locale]} />
      {alternativeLocalesMetaTags}
      <meta property="og:site_name" content={OG_SITE_NAME} />
      <meta property="og:title" content={seo.title} />
      <meta property="og:type" content={OG_TYPE} />
      <meta property="og:url" content={`https://dengi.ru${pathname}`} />
    </Helmet>
  );
};

export default SEO;
