export const createSlicer = (...args) => {
  return (formatedData) => {
    const { data, meta } = formatedData;

    return {
      data: data.slice(...args),
      meta,
    };
  };
};
