import { useCallback, useEffect, useState } from 'react';

import { usePreviousValue } from '../usePreviousValue';

const COOKIES_STORAGE_KEY = 'dengiruCookiePermission';

/**
 * React-хук, управляющий cookie.
 *
 * @returns { accept: void, isAccepted: boolean } - метод установки принятия куки, состояние принятия куки.
 */

export const useCookiesAcceptance = () => {
  const [isAccepted, setIsAccepted] = useState(() => {
    return Boolean(localStorage.getItem(COOKIES_STORAGE_KEY));
  });

  const accept = useCallback(() => {
    setIsAccepted(true);
  }, []);

  const prevIsAccepted = usePreviousValue(isAccepted);

  useEffect(() => {
    if (prevIsAccepted === undefined || isAccepted === prevIsAccepted) {
      return;
    }

    if (isAccepted) {
      localStorage.setItem(COOKIES_STORAGE_KEY, 'true');
      return;
    }
  }, [isAccepted, prevIsAccepted]);

  return {
    accept,
    isAccepted,
  };
};
