import { useTranslation } from 'react-i18next';

import LocationBlock from '../blocks/locationBlock';
import TopBanner from '../blocks/topBanner';
import SEO from '../components/seo';

const Map = () => {
  const { t, i18n } = useTranslation(['location_page']);

  return (
    <>
      <SEO seo={t('seo')} locale={i18n.language} />

      <TopBanner banner={t('topBannerData')} />

      <LocationBlock filter={t('filter')} locale={i18n.language} />
    </>
  );
};

export default Map;
