const Features3RowItem = (props) => {
  const { data } = props;

  return (
    <div className="Features3rows-Item">
      <img
        alt={data.title}
        className="Features3rows-Item-Icon"
        height={64}
        src={data.icon}
        width={64}
      />

      <h3 className="Features3rows-Item-Title">{data.title}</h3>

      <div className="Features3rows-Item-Description">{data.description}</div>
    </div>
  );
};

export default Features3RowItem;
