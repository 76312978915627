/**
 * Приводит данные к формату объекта, добавляя еще пустой объект в поле meta.
 *
 * @param {*} date
 * @param {*} locale
 * @returns {{ data: {day: number, monthName: string, fullYear: number } }}
 */
export const dateParse = (date, locale) => {
  const newDate = new Date(date);
  const month = newDate.toLocaleString(locale, { month: 'long' });

  const capitalizeFirstLetterMonch =
    month.charAt(0).toUpperCase() + month.slice(1);

  return {
    day: newDate.getDate(),
    monthName: capitalizeFirstLetterMonch,
    fullYear: newDate.getFullYear(),
  };
};
