import { Link } from 'react-router-dom';

import { ReactComponent as ArrowLeftIcon } from '../../icons/arrow-left.svg';

import styles from './AllNewsButton.module.css';

export const AllNewsButton = (props) => {
  const { children, ...restProps } = props;

  return (
    <Link className={styles.root} {...restProps}>
      <ArrowLeftIcon className={styles.icon} />

      {children}
    </Link>
  );
};

AllNewsButton.propTypes = Link.propTypes;
