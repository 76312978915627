import classNames from 'classnames';
import PropTypes from 'prop-types';
import * as React from 'react';

import { Accordion } from '../../components/Accordion';
import { ReactComponent as SmallCaretDownIcon } from '../../icons/small-caret-down.svg';
import { ReactComponent as SmallCaretUpIcon } from '../../icons/small-caret-up.svg';
import { checkElementInViewport } from '../../utils/checkElementInViewport';

import styles from './Navigation.module.css';
import { documentPropTypes } from './propTypes';

export const Navigation = (props) => {
  const { groups, mainBlockRef, onSelect, selectedDocumentPath } = props;

  const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false);

  const close = React.useCallback(() => {
    setIsMobileMenuOpen(false);
  }, []);
  const open = React.useCallback(() => {
    setIsMobileMenuOpen(true);
  }, []);

  const selectedGroupName = React.useMemo(() => {
    return groups[selectedDocumentPath.group].title;
  }, [groups, selectedDocumentPath]);

  const handleSelect = React.useCallback(
    ({ documentIndex, groupIndex }) => {
      onSelect({
        document: documentIndex,
        group: groupIndex,
      });

      if (!checkElementInViewport(mainBlockRef.current)) {
        mainBlockRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    },
    [onSelect, mainBlockRef],
  );

  const menu = React.useMemo(() => {
    return (
      <Accordion
        items={groups.map((group, groupIndex) => {
          return {
            title: group.title,
            content: (
              <ul className={styles.list}>
                {group.documents.map((document, documentIndex) => {
                  const isSelected =
                    selectedDocumentPath.group === groupIndex &&
                    selectedDocumentPath.document === documentIndex;
                  const label = document.current.label;

                  return (
                    <li className={styles.item} key={label}>
                      <button
                        className={classNames(styles.button, {
                          [styles.isSelected]: isSelected,
                        })}
                        onClick={() => {
                          handleSelect({
                            documentIndex,
                            groupIndex,
                          });

                          close();
                        }}
                        type="button"
                      >
                        {label}
                      </button>
                    </li>
                  );
                })}
              </ul>
            ),
          };
        })}
      />
    );
  }, [
    close,
    groups,
    handleSelect,
    selectedDocumentPath.document,
    selectedDocumentPath.group,
  ]);

  return (
    <div
      className={classNames(styles.root, {
        [styles.isOpened]: isMobileMenuOpen,
      })}
    >
      <button className={styles.revealer} onClick={open} type="button">
        {selectedGroupName}

        <SmallCaretDownIcon className={styles.revealerIcon} />
      </button>

      <div className={styles.popup}>
        <button
          className={classNames(styles.revealer, styles.isRevealed)}
          onClick={close}
          type="button"
        >
          {selectedGroupName}

          <SmallCaretUpIcon className={styles.revealerIcon} />
        </button>

        <div className={styles.popupContent}>{menu}</div>
      </div>

      <div className={styles.overlay} onClick={close} role="img" />
    </div>
  );
};

Navigation.propTypes = {
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      documents: PropTypes.arrayOf(
        PropTypes.shape({
          current: documentPropTypes.isRequired,
        }),
      ).isRequired,
      title: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
  selectedDocumentPath: PropTypes.shape({
    document: PropTypes.number.isRequired,
    group: PropTypes.number.isRequired,
  }).isRequired,
  mainBlockRef: PropTypes.shape({
    current: PropTypes.object,
  }).isRequired,
};
