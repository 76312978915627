import axios from 'axios';

import { format } from '../utils/format';
import { sortByKey } from '../utils/sortByKey';

/**
 * Возвращает список всех алиасов новостей с годами к которым они относятся.
 *
 * @param {Object} params
 * @param {string} params.lang
 * @returns {{ data: {years: [{year: "2023",news: [{ alias: "4st-news-2023" }]}]}}}
 *
 * @example
 *
 * const { data, meta } = await getListYears({
 *   lang: 'ru'
 * });
 */
export const getListYears = async (params) => {
  const { lang, sort = 'ASK' } = params;

  try {
    const { data } = await axios.get(`/data/news/${lang}/__index.json`);

    const sortedData = sortByKey(data, 'year', sort);

    return format(sortedData);
  } catch {
    return Promise.reject('NOT_FOUND');
  }
};
