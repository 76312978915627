import PropTypes from 'prop-types';

import styles from './NewsBreadCrumbsContainer.module.css';

export const NewsBreadCrumbsContainer = ({ children }) => (
  <div className={styles.root}>{children}</div>
);

NewsBreadCrumbsContainer.propTypes = {
  children: PropTypes.node.isRequired,
};
