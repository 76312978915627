import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './Box.module.css';

export const Box = (props) => {
  const { children, tag: RootComponent = 'div', variant = 'white' } = props;

  return (
    <RootComponent
      className={classNames(styles.root, styles[`variant-${variant}`])}
    >
      {children}
    </RootComponent>
  );
};

Box.propTypes = {
  children: PropTypes.node,
  tag: PropTypes.string,
  variant: PropTypes.oneOf(['gray', 'white']),
};
