import '../styles/components/button.scss';

const Button = (props) => {
  const {
    className,
    click = () => {},
    link = '',
    style = 'Simple',
    title,
  } = props;

  return (
    <a
      href={link}
      className={`Button Button-${style} ${className}`}
      onClick={click}
    >
      {title}
    </a>
  );
};

export default Button;
