import PropTypes from 'prop-types';

import styles from './ErrorNews.module.css';

export const ErrorNews = ({ children }) => (
  <div className={styles.ErrorNews}>{children}</div>
);

ErrorNews.propTypes = {
  children: PropTypes.node.isRequired,
};
