import Button from '../components/button';
import CustomList from '../components/customList';
import '../styles/components/switcher.scss';
import SendReceiveImage from '../static/send.png';

const SwitcherContent = ({ item }) => {
  return (
    <>
      <div className="SwitchContent-SlideContent-Right">
        <img
          alt={item.title ?? item.subtitle}
          src={SendReceiveImage}
          width={416}
          height={416}
        />
      </div>
      <div className="SwitchContent-SlideContent-Left">
        <div className="SwitchContent-Item">
          {item.title && (
            <h3 className="SwitchContent-Item-Title">{item.title}</h3>
          )}
          {item.subtitle && (
            <p className="SwitchContent-Item-Subtitle">{item.subtitle}</p>
          )}
        </div>
        {item.list && (
          <div className="SwitchContent-Item-NumberedList">
            <CustomList items={item.list} style={item.listStyle} />
          </div>
        )}
        {item.button && (
          <div className="SwitchContent-Item-MapButton">
            <Button title={item.button.title} link={item.button.href} />
          </div>
        )}
      </div>
    </>
  );
};

export default SwitcherContent;
