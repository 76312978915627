import CustomListItem from '../components/customListItem';

const CustomList = ({ items, style = '' }) => {
  return (
    <ol className="CustomNumberedList">
      {items.map((item, index) => (
        <CustomListItem key={index} index={index} item={item} style={style} />
      ))}
    </ol>
  );
};

export default CustomList;
