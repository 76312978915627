import { useEffect, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import Button from '../../components/button';
import CustomList from '../../components/customList';
import '../../styles/blocks/mobileApp.scss';

const ANCHOR = 'mobileApp';

export const MobileApp = (props) => {
  const { mobileAppContent } = props;

  const location = useLocation();

  const ref = useRef(null);

  const optionFromUrl = useMemo(() => {
    const hash = location.hash.slice(1);

    if (ANCHOR === hash) {
      return hash;
    }

    return null;
  }, [location]);

  useEffect(() => {
    const { shouldScroll = false } = location.state ?? {};

    if (optionFromUrl && shouldScroll) {
      ref.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location.state, optionFromUrl]);

  return (
    <div className="Block" ref={ref}>
      <div className="Block-Container Block-Container-Centered MobileApp">
        <h2 className="MobileApp-Title">{mobileAppContent.title}</h2>

        <div className="MobileApp-Container">
          <h3 className="MobileApp-Subtitle">{mobileAppContent.subtitle}</h3>

          {mobileAppContent.list && (
            <div className="MobileApp-NumberedList">
              <CustomList
                items={mobileAppContent.list}
                style={mobileAppContent.listStyle}
              />
            </div>
          )}

          {mobileAppContent.buttons && (
            <div className="MobileApp-MapButtons">
              {mobileAppContent.buttons.map((button) => (
                <Button
                  className="MobileApp-Button"
                  key={button.title}
                  link={button.href}
                  title={<span className="Button-Title">{button.title}</span>}
                  style={button.style}
                />
              ))}
            </div>
          )}

          <div className="MobileApp-ImageContanter">
            <img
              alt={mobileAppContent.title ?? mobileAppContent.subtitle}
              className="MobileApp-ImageContanter-Image"
              src={mobileAppContent.image}
              width={416}
              height={416}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
