/**
 * Возвращает функцию фильтрации списка данных.
 *
 * @param {Object.<string, Rule>} rule — Объект, ключами которого являются имена полей в объекте данных, а значениями — функция фильтрации.
 * @returns {(formatedData: { data: Data[], meta: Meta }) => { data: Data[], meta: Meta }}
 *
 * @examples
 *
 * const foo = {
 *   data: [{ a: 1, b: true }, { a: 2, b: false }, { a: 3, b: true }, { a: 4, b: false }],
 *   meta: {},
 * };
 *
 * const filter = createFilter({
 *  a: (value) => value > 2,
 *  b: (value) => value,
 * });
 * const bar = filter(foo);
 *
 * console.log(bar); // => { data: [{ a: 3, b: true }], meta: {} }
 */

/**
 * Callback проверки элемента.
 *
 * @callback Rule
 * @param {*} value
 * @returns {boolean} — Если возвращается false, то объект исключается из списка данных.
 */
export const createFilter = (rules) => {
  return (formatedData) => {
    const { data, meta } = formatedData;

    return {
      data: data.filter((item) => {
        return Object.entries(rules).every(([name, condition]) => {
          return condition(item[name]);
        });
      }),
      meta,
    };
  };
};
