/**
 * Приводит данные к формату объекта, добавляя еще пустой объект в поле meta.
 *
 * @param {*} data
 * @returns {{ data: *, meta: Meta }}
 */
export const format = (data) => {
  return {
    data,
    meta: {},
  };
};
