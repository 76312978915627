import PropTypes from 'prop-types';

import styles from './NewsPageContainer.module.css';

export const NewsPageContainer = ({ children }) => {
  return <div className={styles.root}>{children}</div>;
};

NewsPageContainer.propTypes = {
  children: PropTypes.node.isRequired,
};
