import { createPortal } from 'react-dom';

import styles from './styles.module.css';

export const Popup = (props) => {
  const { children } = props;

  return createPortal(
    <>
      <div className={styles.popup}>{children}</div>

      <div className={styles.overlay} />
    </>,
    document.body,
  );
};
