import PropTypes from 'prop-types';

import styles from './CurrentNewsPageContainer.module.css';

export const CurrentNewsPageContainer = ({ children }) => {
  return <div className={styles.root}>{children}</div>;
};

CurrentNewsPageContainer.propTypes = {
  children: PropTypes.node.isRequired,
};
