import { useCallback, useState } from 'react';

import ToggleBlock from '../components/toggleBlock';
import '../styles/blocks/faq.scss';

const ACTIVE_ITEM_DEFAULT = 0;

const FAQ = (props) => {
  const { faqContent } = props;

  const [activeItem, setActiveItem] = useState(ACTIVE_ITEM_DEFAULT);

  const toggle = useCallback(
    (index) => {
      if (activeItem === index) {
        return setActiveItem(null);
      }

      setActiveItem(index);
    },
    [activeItem],
  );

  return (
    <div className="Block">
      <div className="Block-Container Block-Container-Centered FAQ">
        <div className="FAQ-Container">
          <h2 className="FAQ-Title">{faqContent.title}</h2>

          <div className="FAQ-ListQuestions">
            {faqContent.items.map((faq, index) => (
              <ToggleBlock
                faq={faq}
                isActive={index === activeItem}
                key={index}
                onToggle={() => toggle(index)}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
