import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  AllNewsButton,
  CurrentNewsPageContainer,
  NewsBreadCrumbsContainer,
  NewsCurrentContainer,
  NewsCurrent,
  OtherNews,
} from '../components/OneNews';
import { ErrorNews } from '../components/OneNews/ErrorNews';
import SEO from '../components/seo';
import { getItem, getRelatedList } from '../models/News';

const CurrentNews = () => {
  const { t, i18n } = useTranslation(['news_page']);
  const { alias, year } = useParams();

  const [error, setError] = useState(null);

  const [news, setNews] = useState(null);

  const [otherNews, setOrherNews] = useState({
    data: [],
  });

  const allNewsLink = `/${i18n.language}/news`;

  useEffect(() => {
    const load = async () => {
      const [newsItemResult, relatedNewsListResult] = await Promise.allSettled([
        getItem({ alias, lang: i18n.language, year }),
        getRelatedList({ alias, lang: i18n.language }),
      ]);

      if (newsItemResult.status === 'rejected') {
        setError(t('newsPage.newsNotFound'));
      }

      setNews(newsItemResult.value);

      if (relatedNewsListResult.value) {
        setOrherNews(relatedNewsListResult.value);
      }
    };
    load();
  }, [alias, year, i18n.language, t]);

  if (error) {
    return (
      <CurrentNewsPageContainer>
        <NewsCurrentContainer>
          <ErrorNews>{error}</ErrorNews>
        </NewsCurrentContainer>
      </CurrentNewsPageContainer>
    );
  }

  return (
    <>
      <SEO seo={news?.data?.seo || t('seo')} locale={i18n.language} />

      <CurrentNewsPageContainer>
        <NewsBreadCrumbsContainer>
          <AllNewsButton to={allNewsLink}>
            {t('newsPage.allNews')}
          </AllNewsButton>
        </NewsBreadCrumbsContainer>

        <NewsCurrentContainer>
          <NewsCurrent locale={i18n.language} news={news?.data} />
          <OtherNews
            allNewsLink={allNewsLink}
            locale={i18n.language}
            newsList={otherNews?.data}
          />
        </NewsCurrentContainer>
      </CurrentNewsPageContainer>
    </>
  );
};

export default CurrentNews;
