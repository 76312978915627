import PropTypes from 'prop-types';

import styles from './NewsButton.module.css';

export const NewsButton = (props) => {
  const { children, onClick } = props;

  return (
    <button className={styles.root} onClick={onClick} type="button">
      {children}
    </button>
  );
};

NewsButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};
