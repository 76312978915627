import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './Switcher.module.css';

export const Switcher = (props) => {
  const { items, onSwitch, value } = props;

  return (
    <div className={styles.root}>
      {items.map((item) => {
        return (
          <button
            className={classNames(styles.item, {
              [styles.isSelected]: value === item.value,
            })}
            key={item.value}
            onClick={() => onSwitch(item.value)}
            type="button"
          >
            {item.label}
          </button>
        );
      })}
    </div>
  );
};

Switcher.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node.isRequired,
      value: PropTypes.number.isRequired,
    }),
  ).isRequired,
  onSwitch: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
};
