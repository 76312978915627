import { useTranslation } from 'react-i18next';

import BlockBorder from '../blocks/blockBorder';
import CallbackForm from '../blocks/callbackForm';
import TopBanner from '../blocks/topBanner';
import SEO from '../components/seo';

const Partners = () => {
  const { t, i18n } = useTranslation(['partners_page']);

  return (
    <>
      <SEO seo={t('seo')} locale={i18n.language} />

      <TopBanner banner={t('topBannerData')} />

      <BlockBorder
        content={t('startService')}
        additionalBlockStyle="BorderBlock-BottomMargin"
      />

      <BlockBorder
        content={t('serviceSupport')}
        additionalBlockStyle="BorderBlockLast-BottomMargin"
      />

      <CallbackForm />
    </>
  );
};

export default Partners;
