/* eslint-disable react/no-danger */
const CustomListSubItem = ({ item, index }) => {
  return (
    <li key={index}>
      <span dangerouslySetInnerHTML={{ __html: item }} />
    </li>
  );
};

export default CustomListSubItem;
