import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import 'swiper/swiper-bundle.min.css';

import Button from '../components/button';
import SliderLeftArrow from '../static/icons/slider-left.svg';
import SliderRightArrow from '../static/icons/slider-right.svg';
import '../styles/blocks/slider.scss';

const Slider = ({ slides }) => {
  const history = useHistory();

  const handleClick = useCallback(
    (event, href, isAnchor = false) => {
      if (isAnchor) {
        event.preventDefault();

        history.push(href, { shouldScroll: true });
      }
    },
    [history],
  );

  return (
    <div className="Block SliderBlock">
      <div className="Block-Container SliderBlock-Container Block-Container-Centered">
        <Swiper
          modules={[Navigation, Pagination]}
          slidesPerView={1}
          navigation={{
            enabled: true,
            prevEl: '.SliderBlock-Container-Navigation-LeftArrow',
            nextEl: '.SliderBlock-Container-Navigation-RightArrow',
          }}
          pagination={{
            clickable: true,
            el: '.SliderBlock-Container-Navigation-Pagination',
            bulletClass: 'SliderBlock-Container-Navigation-Pagination-Bullet',
            bulletActiveClass:
              'SliderBlock-Container-Navigation-Pagination-Bullet-Active',
          }}
        >
          {slides &&
            slides.map((item) => (
              <SwiperSlide key={item.title}>
                <div className="SliderBlock-Container-Slide">
                  <div className="SliderBlock-Container-Slide-Right SliderBlock-Container-Slide-Image">
                    <img
                      alt={item.title}
                      src={item.image}
                      height={380}
                      width={380}
                    />
                  </div>
                  <div className="SliderBlock-Container-Slide-Left">
                    <div
                      className="SliderBlock-Container-Slide-Title"
                      dangerouslySetInnerHTML={{ __html: item.title }}
                    />

                    {item.subtitle && (
                      <div
                        className="SliderBlock-Container-Slide-Subtitle"
                        dangerouslySetInnerHTML={{ __html: item.subtitle }}
                      />
                    )}

                    <div className="SliderBlock-Container-Slide-Buttons">
                      {item.buttons &&
                        item.buttons.map((button) => (
                          <Button
                            click={(event) =>
                              handleClick(event, button.href, button.isAnchor)
                            }
                            key={button.title}
                            link={button.href}
                            style={button.style}
                            title={
                              <span className="Button-Title">
                                {button.title}
                              </span>
                            }
                          />
                        ))}
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          <div className="SliderBlock-Container-Navigation">
            <div className="SliderBlock-Container-Navigation-LeftArrow">
              <img alt="" height={22} src={SliderLeftArrow} width={28} />
            </div>

            <div className="SliderBlock-Container-Navigation-Pagination"></div>

            <div className="SliderBlock-Container-Navigation-RightArrow">
              <img alt="" height={22} src={SliderRightArrow} width={28} />
            </div>
          </div>
        </Swiper>
      </div>
    </div>
  );
};

export default Slider;
