import Interpolate from '@doist/react-interpolate';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import * as React from 'react';

import styles from './PublicationDate.module.css';

export const PublicationDate = (props) => {
  const {
    classNames: { date: classNameDate, root: classNameRoot } = {},
    date,
    label,
    locale,
    ...restProps
  } = props;

  const formattedDate = React.useMemo(() => {
    return new Date(date).toLocaleDateString(locale, {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  }, [date, locale]);

  return (
    <p {...restProps} className={classNames(styles.root, classNameRoot)}>
      <Interpolate
        mapping={{
          date: (
            <span className={classNames(styles.date, classNameDate)}>
              {formattedDate}
            </span>
          ),
        }}
        string={label}
      />
    </p>
  );
};

PublicationDate.propTypes = {
  date: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,

  classNames: PropTypes.shape({
    date: PropTypes.string,
    root: PropTypes.string,
  }),
};
