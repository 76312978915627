import axios from 'axios';

import { format } from '../utils/format';
import { sortByDate } from '../utils/sortByDate';

/**
 * Возвращает список всех новостей за год.
 *
 * @param {Object} params
 * @param {string} params.lang
 * @param {string} params.year
 * @param {string} params.sort
 * @returns {{ data: News[] }}
 *
 * @example
 *
 * const { data, meta } = await getListByYear({
 *   lang: 'ru',
 *   year: '2023',
 *   sort?: 'DESK',
 * });
 */
export const getListByYear = async (params) => {
  const { lang, year, sort = 'DESK' } = params;

  try {
    const { data } = await axios.get(`/data/news/${lang}/${year}.json`);

    const sortedData = sortByDate(data, sort);

    return format(sortedData);
  } catch {
    return Promise.reject('NOT_FOUND');
  }
};
