import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './Reveal.module.css';

export const Reveal = (props) => {
  const { children, className, isRevealed = false, ...restProps } = props;

  return (
    <div
      {...restProps}
      aria-hidden={!isRevealed}
      className={classNames(styles.root, className, {
        [styles.isRevealed]: isRevealed,
      })}
    >
      <div className={styles.content}>{children}</div>
    </div>
  );
};

Reveal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  isRevealed: PropTypes.bool,
};
