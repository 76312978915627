import axios from 'axios';
import { Formik, Field, Form, useField } from 'formik';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import * as yup from 'yup';

import '../styles/blocks/callbackForm.scss';
import iconFail from '../static/icons/iconFail.svg';
import iconSuccess from '../static/icons/iconSuccess.svg';
import { ym } from '../utils/ym';

const CallbackForm = () => {
  const { t, i18n } = useTranslation(['common']);
  const [readOnlyForm, setReadOnlyForm] = useState(false);
  const [screen, setScreen] = useState('form');

  const handleSubmitForm = (values) => {
    setReadOnlyForm(true);
    setTimeout(() => {
      axios({
        method: 'post',
        url: '/api/v1/callback',
        data: values,
        headers: { 'Content-Type': 'application/json' },
      })
        .then(() => {
          setScreen('success');
          setReadOnlyForm(false);
          ym(93661975, 'reachGoal', 'form_submit');
        })
        .catch(function () {
          setScreen('error');
          setReadOnlyForm(false);
        });
    }, 500);
  };

  const CustomTextArea = ({ label, maxLen, ...props }) => {
    const [field] = useField(props);

    const [message] = useState('');
    const [textareaLength, setTextareaLength] = useState(0);

    const handleMessageChange = (event) => {
      setTextareaLength(event.target.value.length);
    };

    return (
      <>
        <div className="textareaLabel">
          <div className="textareaCounter">
            {textareaLength} / {maxLen}
          </div>
          {label}
        </div>

        <textarea
          className="callbackForm-Form-Text callbackForm-Form-Textarea"
          maxLength={maxLen}
          value={message}
          {...field}
          {...props}
          onKeyUp={handleMessageChange}
        />
      </>
    );
  };

  const validationSchema = yup.object({
    CF_mail: yup
      .string(t('callbackForm.validation.mail'))
      .min(2, t('callbackForm.validation.mail'))
      .required(t('callbackForm.validation.mail')),
    CF_name: yup
      .string(t('callbackForm.validation.name'))
      .min(2, t('callbackForm.validation.name'))
      .required(t('callbackForm.validation.name')),
    CF_question: yup
      .string(t('callbackForm.validation.question'))
      .min(2, t('callbackForm.validation.question'))
      .required(t('callbackForm.validation.question')),
    CF_agreement: yup
      .bool()
      .oneOf([true], t('callbackForm.validation.agreement')),
  });

  const agreementLink = (
    <a
      className="AgreementLink"
      href={t('callbackForm.form.agreementLink')}
      rel="noreferrer"
      target="_blank"
    >
      {t('callbackForm.form.agreementLinkText')}
    </a>
  );

  const agreement = (
    <span className="Agreement-Text">
      {i18n.language === 'tr' ? (
        <>
          {agreementLink}
          {t('callbackForm.form.agreement')}
        </>
      ) : (
        <>
          {t('callbackForm.form.agreement')}
          {agreementLink}
        </>
      )}
    </span>
  );

  return (
    <div className="Block">
      <div className="callbackForm" id="callback">
        <div className="callbackForm-Panel">
          <div
            className="callbackForm-Title"
            dangerouslySetInnerHTML={{
              __html: t('callbackForm.title'),
            }}
          />

          <div
            className="callbackForm-Subtitle"
            dangerouslySetInnerHTML={{
              __html: t('callbackForm.subtitle'),
            }}
          />

          <div className="callbackForm-Form">
            {screen === 'form' && (
              <Formik
                initialValues={{
                  CF_mail: '',
                  CF_name: '',
                  CF_question: '',
                  CF_agreement: false,
                }}
                onSubmit={async (values) => {
                  handleSubmitForm(values);
                }}
                initialStatus="default"
                validationSchema={validationSchema}
              >
                {({ errors, touched }) => (
                  <Form className="callbackForm-Form-CF">
                    <Field
                      type="text"
                      placeholder={t('callbackForm.form.name')}
                      name="CF_name"
                      className="callbackForm-Form-Text"
                      disabled={readOnlyForm}
                    />

                    {errors.CF_name && touched.CF_name ? (
                      <div className="callbackForm-Form-ValidationError">
                        {t('callbackForm.validation.name')}
                      </div>
                    ) : null}

                    <Field
                      type="text"
                      placeholder={t('callbackForm.form.mail')}
                      name="CF_mail"
                      className="callbackForm-Form-Text"
                      disabled={readOnlyForm}
                    />

                    {errors.CF_mail && touched.CF_mail ? (
                      <div className="callbackForm-Form-ValidationError">
                        {t('callbackForm.validation.mail')}
                      </div>
                    ) : null}

                    <CustomTextArea
                      label={t('callbackForm.form.question')}
                      maxLen="256"
                      name="CF_question"
                      rows="4"
                      placeholder={t('callbackForm.form.usercomment')}
                      disabled={readOnlyForm}
                    />
                    {errors.CF_question && touched.CF_question ? (
                      <div className="callbackForm-Form-ValidationError">
                        {t('callbackForm.validation.question')}
                      </div>
                    ) : null}

                    <button
                      type="submit"
                      className="callbackForm-Form-Submit"
                      disabled={readOnlyForm}
                    >
                      {t('callbackForm.form.submit')}
                    </button>

                    <div className="callbackForm-Form-Disclaimer">
                      <Trans
                        i18nKey="callbackForm.form.agreementDisclaimer"
                        components={{
                          disclaimer_link: (
                            <a
                              className="callbackForm-Form-Disclaimer-Link"
                              href={t(
                                'callbackForm.form.agreementDisclaimerLink',
                              )}
                              title={t('callbackForm.form.agreementDisclaimer')}
                              rel="noopener noreferrer"
                              target="_blank"
                            />
                          ),
                        }}
                      />
                    </div>

                    <div className="callbackForm-Form-Agreement">
                      <label>
                        <Field
                          type="checkbox"
                          name="CF_agreement"
                          disabled={readOnlyForm}
                        />

                        {agreement}
                      </label>
                    </div>
                    {errors.CF_agreement && touched.CF_agreement ? (
                      <div className="callbackForm-Form-ValidationError">
                        {t('callbackForm.validation.agreement')}
                      </div>
                    ) : null}
                  </Form>
                )}
              </Formik>
            )}

            {screen === 'error' && (
              <div className="callbackForm-InfoPanel">
                <div className="callbackForm-InfoPanel-Icon">
                  <img src={iconFail} />
                </div>
                <div
                  className="callbackForm-InfoPanel-Text-Error"
                  dangerouslySetInnerHTML={{
                    __html: t('callbackForm.resultFail'),
                  }}
                />
              </div>
            )}

            {screen === 'success' && (
              <div className="callbackForm-InfoPanel">
                <div className="callbackForm-InfoPanel-Icon">
                  <img src={iconSuccess} />
                </div>
                <div
                  className="callbackForm-InfoPanel-Text-Success"
                  dangerouslySetInnerHTML={{
                    __html: t('callbackForm.resultSuccess'),
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CallbackForm;
