import Features3RowItem from '../components/features3rows-item';
import headerLogo from '../static/headerLogo.svg';
import '../styles/blocks/features3Rows.scss';

const Features3Rows = (props) => {
  const { data } = props;

  return (
    <div className="Block Features3rows">
      <div className="Block-Container Block-Container-Centered Features3rows-Container">
        <h2 className="Features3rows-Title">
          <img
            alt="dengi.ru"
            className="Features3rows-Logo"
            height={32}
            src={headerLogo}
            width={160}
          />

          <span className="Features3rows-LongDash">—</span>

          <span>{data.title}</span>
        </h2>

        <div className="Features3rows-Items">
          {data.items.map((item) => (
            <Features3RowItem data={item} key={item.icon} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Features3Rows;
