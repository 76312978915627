import PropTypes from 'prop-types';
import Markdown from 'react-markdown';
import { Link } from 'react-router-dom';

import { dateParse } from '../../models/News';

import styles from './NewsList.module.css';

export const NewsList = (props) => {
  const { locale, newsList, year } = props;

  const { t, i18n } = locale;

  const newsDateParse = (date) => {
    const { day, monthName, fullYear } = dateParse(date, i18n.language);

    return `${monthName} ${day}, ${fullYear}`;
  };

  if (newsList?.length < 1) {
    return <div className={styles.noNews}>{t('newsPage.noNews')}</div>;
  }

  return (
    <div className={styles.root}>
      {newsList.map((news) => {
        return (
          <Link
            className={styles.item}
            key={news.alias}
            to={`/${i18n.language}/news/${year}/${news.alias}`}
          >
            {news.imageUrl ? (
              <div className={styles.imageBox}>
                <img
                  alt={news.title}
                  className={styles.image}
                  src={news.imageUrl}
                />
              </div>
            ) : null}

            <div className={styles.newsBox}>
              <div className={styles.date}>{newsDateParse(news.createdAt)}</div>

              <h3 className={styles.title}>{news.title}</h3>

              <Markdown className={styles.description}>
                {news.description}
              </Markdown>
            </div>
          </Link>
        );
      })}
    </div>
  );
};

NewsList.propTypes = {
  year: PropTypes.number,

  newsList: PropTypes.array,
  locale: PropTypes.shape({
    t: PropTypes.func,
    i18n: PropTypes.object,
  }),
  onClickNews: PropTypes.func,
};
