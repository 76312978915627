import PropTypes from 'prop-types';
import * as React from 'react';

import { Box } from '../../components/Box';

import { Archive } from './Archive';
import styles from './Documents.module.css';
import { DownloadLink } from './DownloadLink';
import { Navigation } from './Navigation';
import { PublicationDate } from './PublicationDate';
import { documentPropTypes } from './propTypes';

export const Documents = (props) => {
  const { groups, locale, texts } = props;

  const mainBlockRef = React.useRef(null);

  const [selectedDocumentPath, setSelectedDocumentPath] = React.useState({
    group: 0,
    document: 0,
  });

  const selectedDocument = React.useMemo(() => {
    return groups[selectedDocumentPath.group].documents[
      selectedDocumentPath.document
    ];
  }, [groups, selectedDocumentPath]);

  return (
    <div className={styles.root}>
      <aside className={styles.navigation}>
        <Navigation
          groups={groups}
          mainBlockRef={mainBlockRef}
          onSelect={setSelectedDocumentPath}
          selectedDocumentPath={selectedDocumentPath}
        />
      </aside>

      <section className={styles.content} ref={mainBlockRef}>
        <Box variant="gray">
          <PublicationDate
            date={selectedDocument.current.publicationDate}
            label={texts['publication_date']}
            locale={locale}
          />

          <DownloadLink href={selectedDocument.current.link}>
            {selectedDocument.current.label}
          </DownloadLink>
        </Box>
      </section>

      {selectedDocument.archived?.length > 0 ? (
        <section className={styles.archive}>
          <Archive
            documents={selectedDocument.archived}
            locale={locale}
            texts={texts}
          />
        </section>
      ) : null}
    </div>
  );
};

Documents.propTypes = {
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      documents: PropTypes.arrayOf(
        PropTypes.shape({
          current: documentPropTypes.isRequired,

          archived: PropTypes.arrayOf(documentPropTypes),
        }),
      ).isRequired,
      title: PropTypes.string.isRequired,
    }),
  ).isRequired,
  locale: PropTypes.string.isRequired,
  texts: PropTypes.objectOf(PropTypes.string).isRequired,
};
