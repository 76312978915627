import '../styles/components/switcher.scss';

const Switcher = ({
  items,
  className,
  additionalClassname = '',
  currentAlias,
  handleFunction,
}) => {
  return (
    <div className={`${className} ${additionalClassname} Switcher-Block`}>
      {items.map((item) => {
        return (
          <div
            key={item.option}
            className={`Switcher-Block-Button ${
              additionalClassname ? additionalClassname + '-Button' : ''
            } ${
              currentAlias === item.option ? 'Switcher-Block-Button-Active' : ''
            }`}
            onClick={() => handleFunction(item.option)}
          >
            {item.title}
          </div>
        );
      })}
    </div>
  );
};

export default Switcher;
