import PropTypes from 'prop-types';
import Markdown from 'react-markdown';

import { dateParse } from '../../models/News';

import styles from './NewsCurrent.module.css';

export const NewsCurrent = ({ locale, news }) => {
  const newsDateParse = (date) => {
    const { day, monthName, fullYear } = dateParse(date, locale);

    return `${monthName} ${day}, ${fullYear}`;
  };

  if (!news) {
    return null;
  }

  return (
    <div className={styles.root}>
      <div className={styles.date}>{newsDateParse(news?.createdAt)}</div>

      <h1 className={styles.title}>{news?.title}</h1>

      {news?.imageUrl ? (
        <div className={styles.imageBox}>
          <img className={styles.image} src={news?.imageUrl} alt={news.title} />
        </div>
      ) : null}

      <Markdown className={styles.content}>{news?.content}</Markdown>
    </div>
  );
};

NewsCurrent.propTypes = {
  news: PropTypes.shape({
    createdAt: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    imageUrl: PropTypes.string,
  }),
};
