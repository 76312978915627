import { createSlicer } from './createSlicer';

const DEFAULT_PER_PAGE = 4;

/**
 * Возвращает пагинированные данные.
 *
 * @param {Meta} meta
 * @returns {{ data: Data[], meta: Meta }}
 */
export const createPagination = (meta) => {
  const { page, perPage = DEFAULT_PER_PAGE } = meta;

  return (formatedData) => {
    const { data } = formatedData;

    const totalItemCount = data.length;
    const pagesCount = Math.ceil(totalItemCount / perPage);

    const firstItemIndex = (page - 1) * perPage;
    const nextFirstItemIndex = firstItemIndex + perPage;

    const slice = createSlicer(firstItemIndex, nextFirstItemIndex);

    return slice({
      data,
      meta: {
        ...formatedData.meta,
        page,
        pagesCount,
        perPage,
        totalItemCount,
      },
    });
  };
};
