import classNames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import Dropdown from '../components/Dropdown/Dropdown';
import LangSelector from '../components/langSelector';
import { ReactComponent as HeaderLogo } from '../static/headerLogoWithPatterns.svg';

import '../styles/blocks/navbar.scss';

const ROUTES = {
  TRANSFER_TO_TURKEY: '/transfer/turkey/',
};

const NavBar = (props) => {
  const { match } = props;
  const { t, i18n } = useTranslation();

  const basePath = `/${i18n.resolvedLanguage}`;

  const location = useLocation();

  const [isShowMobileMenu, setIsShowMobileMenu] = useState(false);
  const [isMobileAppVisible, setMobileAppVisible] = useState(false);

  const toggleMobileAppMenu = () => {
    setMobileAppVisible((isVisible) => !isVisible);
  };

  const toggleMobileMenu = () => {
    setIsShowMobileMenu((current) => !current);
  };

  const showMobileMenu = (menuState) => {
    setIsShowMobileMenu(menuState);
  };

  return (
    <div className="Block">
      <div
        className={`Navbar-Overlay ${
          isShowMobileMenu === true ? 'Navbar-Overlay-Active' : ''
        }`}
        onClick={() => showMobileMenu(false)}
      ></div>
      <nav className="Block-Container Block-Container-Centered">
        <div className="Navbar Navbar-Container">
          <div
            className={`Navbar-Container-MobileMenu ${
              isShowMobileMenu === true
                ? 'Navbar-Container-MobileMenu-Opened'
                : ''
            }`}
          >
            <div
              className="Navbar-Container-MobileMenu-Icon MobileIcon"
              onClick={() => toggleMobileMenu()}
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>

          <Link to={basePath} className="Navbar-Container-Logo">
            <HeaderLogo className="Navbar-Container-Logo-Icon" />
          </Link>

          <div
            className={`Navbar-Container-Menu${
              isShowMobileMenu === true ? '-MobileShown' : ''
            }`}
          >
            <ul>
              <li
                className={classNames({
                  'Navbar-Container-Menu-MobileShown-Item': isShowMobileMenu,
                  'Navbar-Container-Menu-Item': !isShowMobileMenu,
                })}
              >
                <a
                  href={ROUTES.TRANSFER_TO_TURKEY}
                  className={classNames({
                    'Navbar-Container-Menu-MobileShown-Item-Link':
                      isShowMobileMenu,
                    'Navbar-Container-Menu-Item-Link': !isShowMobileMenu,
                  })}
                >
                  {t('navbar.sendMoneyToTurkey')}
                </a>
              </li>
              <li
                className={`Navbar-Container-Menu${
                  isShowMobileMenu ? '-MobileShown' : ''
                }-Item${isShowMobileMenu ? '-Link' : ''}`}
                onClick={toggleMobileAppMenu}
              >
                {t('navbar.mobileApp')}
                <Dropdown
                  hidden={!isMobileAppVisible}
                  items={t('navbar.saveFrom')}
                  popup={!isShowMobileMenu}
                />
              </li>
              <li
                className={`Navbar-Container-Menu${
                  isShowMobileMenu === true ? '-MobileShown' : ''
                }-Item`}
              >
                <Link
                  to={`${basePath}/transfer/find-locations`}
                  onClick={() => showMobileMenu(false)}
                  className={`Navbar-Container-Menu${
                    isShowMobileMenu === true ? '-MobileShown' : ''
                  }-Item-Link ${
                    location.pathname === basePath + '/transfer/find-locations'
                      ? 'Navbar-Container-Menu-Item-Link-Active'
                      : ''
                  }`}
                >
                  {t('navbar.points')}
                </Link>
              </li>
              <li
                className={`Navbar-Container-Menu${
                  isShowMobileMenu === true ? '-MobileShown' : ''
                }-Item`}
              >
                <Link
                  to={`${basePath}/company/contacts`}
                  onClick={() => showMobileMenu(false)}
                  className={`Navbar-Container-Menu${
                    isShowMobileMenu === true ? '-MobileShown' : ''
                  }-Item-Link ${
                    location.pathname === basePath + '/company/contacts'
                      ? 'Navbar-Container-Menu-Item-Link-Active'
                      : ''
                  }`}
                >
                  {t('navbar.contacts')}
                </Link>
              </li>
            </ul>
          </div>
          <div className="Navbar-Container-LangSelector">
            <LangSelector match={match} i18n={i18n} />
          </div>
        </div>
      </nav>
    </div>
  );
};

export default NavBar;
