import { createFilter } from '../utils/createFilter';
import { createSlicer } from '../utils/createSlicer';

import { getListByYear } from './getListByYear';
import { getListYears } from './getListYears';

const DEFAULT_MAX_COUNT = 4;

/**
 * Возвращает список свежих новостей к новости alias.
 *
 * @param {Object} params
 * @param {string | null | undefined} params.alias
 * @param {string} params.lang
 * @param {Object} options
 * @param {number} [options.maxCount=DEFAULT_MAX_COUNT]
 * @returns {{ data: News[] }}
 *
 * @example
 *
 * const { data, meta } = await getRelatedList({
 *   alias: 'some-good-news',
 *   lang: 'ru',
 * });
 */
export const getRelatedList = async (params, options = {}) => {
  const { alias, lang } = params;
  const { maxCount = DEFAULT_MAX_COUNT } = options;

  const filter = createFilter({
    alias: (value) => value !== alias,
  });

  const slice = createSlicer(0, maxCount);

  const { data: yearsData } = await getListYears({ lang, sort: 'DESK' });

  const years = yearsData.reduce(
    (result, item) => {
      const aliases = item.news.filter(
        (newsAlias) => newsAlias.alias !== alias,
      );

      if (result.aliases.length >= maxCount) {
        return result;
      }

      return {
        ...result,
        aliases: [...result.aliases, ...aliases],
        years: [...result.years, item.year],
      };
    },
    {
      years: [],
      aliases: [],
    },
  ).years;

  const newsData = await Promise.all(
    years.map((year) =>
      getListByYear({
        lang,
        year,
      }),
    ),
  );

  const parseData = newsData.reduce(
    (result, item) => ({
      ...result,
      data: [...result.data, ...item.data],
      meta: item.meta,
    }),
    { data: [], meta: {} },
  );

  return slice(filter(parseData));
};
