import { Link } from 'react-router-dom';

import styles from './CurrentNewsButton.module.css';

export const CurrentNewsButton = (props) => {
  const { children, ...restProps } = props;

  return (
    <Link className={styles.root} {...restProps}>
      {children}
    </Link>
  );
};

CurrentNewsButton.propTypes = Link.propTypes;
