import Button from '../components/button';

import '../styles/blocks/topBanner.scss';

const TopBanner = ({ banner }) => {
  return (
    <div className="Block">
      <div className="Block-Container Block-Container-Centered TopBanner">
        <div className="TopBanner-Container">
          <div className="TopBanner-Container-Left">
            <div className="TopBanner-Container-Left-Banner">
              <img
                src={banner.image}
                className="TopBanner-Container-Left-Banner-Image"
              />
            </div>
          </div>
          <div className="TopBanner-Container-Right">
            <div className="TopBanner-Container-Right-Title">
              {banner.title}
            </div>
            <div className="TopBanner-Container-Right-Subtitle">
              {banner.subtitle}
            </div>
            {banner.button && (
              <div className="TopBanner-Container-Right-Button">
                <Button
                  title={banner.button.title}
                  link={banner.button.link}
                  style={banner.button.style}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBanner;
