import { Switcher } from './Switcher';
import styles from './YearSwitcher.module.css';

export const YearSwitcher = (props) => {
  const { selectedYear, items, onSwitch } = props;

  return (
    <div className={styles.root}>
      <Switcher items={items} onSwitch={onSwitch} value={selectedYear} />
    </div>
  );
};

YearSwitcher.propTypes = {
  items: Switcher.propTypes.items,
  onSwitch: Switcher.propTypes.onSwitch,
  selectedYear: Switcher.propTypes.value,
};
