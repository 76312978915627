import './Dropdown.scss';

const Dropdown = (
  { hidden, popup, items, onClick } = {
    hidden: false,
    popup: false,
    onClick: null,
    items: [],
  },
) => (
  <div
    hidden={hidden}
    className={`Dropdown-Panel ${popup ? 'Dropdown-Panel-Popup' : ''}`}
  >
    {items.map((item) => (
      <a
        className={`Dropdown-Panel-Popup-Item ${popup ? 'Dropdown-Panel-Popup-Item-bordered' : ''}`}
        key={item.label}
        href={item.href}
        target="_blank"
      >
        {!popup && item.label}
        {popup && (
          <button
            className={`Dropdown-Item ${item.isSelected ? 'Dropdown-Item-Active' : ''}`}
            disabled={item.disabled}
            onClick={onClick && onClick(item)}
          >
            {item.label}
          </button>
        )}
      </a>
    ))}
  </div>
);

export default Dropdown;
