import axios from 'axios';

/**
 * Возвращает название баннера, который нужно показать, и список всех баннеров.
 *
 * @param {Object} params
 * @param {string} params.lang
 * @returns {{ data: { currentBanner: string, banner: { text: string, link: string } } }}
 *
 * @example
 *
 * const { data } = await getBanners({
 *   lang: 'ru',
 * });
 */

export const getBanners = async (params) => {
  const { lang } = params;

  try {
    const { data } = await axios.get(`/locale/${lang}/banners.json`);

    return data;
  } catch {
    return Promise.reject('NOT_FOUND');
  }
};
