import PropTypes from 'prop-types';

import styles from './PageTitle.module.css';

export const PageTitle = ({ title, description }) => {
  return (
    <div className={styles.root}>
      <h1 className={styles.title}>{title}</h1>

      <p className={styles.description}>{description}</p>
    </div>
  );
};

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};
