import { Trans, useTranslation } from 'react-i18next';

import { ReactComponent as CookiesIcon } from '../../icons/cookies.svg';
import { Modal } from '../Modal';

import styles from './styles.module.css';

export const CookiesAlert = (props) => {
  const { isShown, onAccept } = props;
  const { t } = useTranslation(['common']);

  if (!isShown) {
    return null;
  }

  return (
    <Modal
      action={{
        label: t('cookies.buttonLabel'),
        onClick: onAccept,
      }}
    >
      <CookiesIcon className={styles.icon} />

      <div className={styles.title}>{t('cookies.title')}</div>

      <div className={styles.subtitle}>
        <Trans
          i18nKey="cookies.subtitle"
          components={{
            subtitle_link: (
              <a
                className={styles.agreementLink}
                href={t('cookies.agreementLink')}
                title={t('cookies.agreementTitle')}
                rel="noopener noreferrer"
                target="_blank"
              />
            ),
          }}
        />
      </div>
    </Modal>
  );
};
