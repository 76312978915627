import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

// eslint-disable-next-line import/no-named-as-default-member
i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: '/locale/{{lng}}/{{ns}}.json',
    },
    debug: process.env.NODE_ENV === 'development',
    defaultNS: 'common',
    detection: {
      caches: ['cookie'],
      order: ['path', 'cookie', 'localStorage', 'htmlTag'],
    },
    fallbackLng: 'ru',
    interpolation: {
      escapeValue: false,
    },
    ns: ['common'],
    react: {
      useSuspense: true,
      wait: false,
    },
    returnObjects: true,
  });

export default i18n;
