const CountryItem = ({ code, soon, click }) => {
  const countryIcon = require('../static/icons/country/' + code + '.svg');

  return (
    <div className="CountryBlock-Items-Country">
      <div
        className={`CountryBlock-Items-Country-Image ${
          soon ? 'CountryBlock-Items-Country-Image-Soon' : ''
        }`}
      >
        <img
          alt={code}
          height={32}
          onClick={click}
          src={countryIcon}
          width={32}
        />
      </div>

      {soon ? (
        <div className="CountryBlock-Items-Country-Hint">{soon}</div>
      ) : null}
    </div>
  );
};

export default CountryItem;
