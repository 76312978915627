import { useTranslation } from 'react-i18next';

const NotFound = () => {
  const { t } = useTranslation(['not_found_page']);

  return (
    <div>
      <div className="Block">
        <div className="Black-Container Block-Container-Centered error-page">
          <h1 className="error-code">{t('title')}</h1>

          <p className="error-text">{t('description')}</p>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
