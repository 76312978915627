import '../styles/components/contactsItem.scss';

const ContactsItem = ({ item }) => {
  return (
    <div className="ContactsItem">
      <a
        className={`ContactsItem-Contact ContactsItem-Contact-${item.type}`}
        href={`${item.type === 'phone' ? 'tel:' : 'mailto:'}${item.contact}`}
      >
        {item.contact}
      </a>
      <span className="ContactsItem-Hint">{item.hint}</span>
    </div>
  );
};

export default ContactsItem;
