import { createFilter } from '../utils/createFilter';
import { createPagination } from '../utils/createPagination';
import { same } from '../utils/same';

import { getListByYear } from './getListByYear';

/**
 * Возвращает список новостей за год с применением фильтров и пагинацией.
 *
 * @param {Object} params
 * @param {string} params.lang
 * @param {string} params.year
 * @param {Object} options
 * @param {Rules} options.filter
 * @param {Pagination} options.pagination
 * @returns {{ data: News[], meta: Meta }}
 *
 * @example
 *
 * const { data, meta } = await getList(
 *   {
 *     lang: 'ru',
 *     year: '2023',
 *   },
 *   {
 *     pagination: {
 *       page: 2,
 *     },
 *   },
 * );
 */
export const getList = async (params, options = {}) => {
  const { lang, year } = params;
  const { filter: filterRules, pagination } = options;

  const formatedData = await getListByYear({
    lang,
    year,
  });

  const filter = filterRules ? createFilter(filterRules) : same;
  const paginate = pagination ? createPagination(pagination) : same;

  return paginate(filter(formatedData));
};
