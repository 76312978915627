import '../styles/blocks/blockBorder.scss';
import Contacts from '../components/contacts';
import CustomList from '../components/customList';

const BlockBorder = ({
  content,
  additionalStyle = '',
  additionalBlockStyle = '',
}) => {
  if (content.style === 'green') additionalStyle += ' BorderBlock-Green';
  if (content.style === 'orange') additionalStyle += ' BorderBlock-Orange';

  return (
    <div className="Block BorderBlock">
      <div
        className={`Block-Container Block-Container-Centered BorderBlock-Container ${additionalStyle} ${additionalBlockStyle}`}
      >
        <div className={`BorderBlock-Title ${additionalStyle}-Title`}>
          {content.title}
        </div>
        <div className={`BorderBlock-Content ${additionalStyle}-Content`}>
          {content.list && (
            <CustomList items={content.list} style={content.style} />
          )}
          {content.contentBold && (
            <div className="BorderBlock-Content-BoldText">
              {content.contentBold}
            </div>
          )}
          {content.contacts && <Contacts items={content.contacts} />}
        </div>
      </div>
    </div>
  );
};

export default BlockBorder;
