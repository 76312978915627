const TablePointsList = ({ aliasVariant, branches, filter }) => {
  return (
    <>
      <div className="Locations-Container-Results-ListBlock-Header">
        <div className="Locations-Container-Results-ListBlock-Row">
          <div className="Locations-Container-Results-ListBlock-Cell">
            {filter.point}
          </div>
          <div className="Locations-Container-Results-ListBlock-Cell">
            {filter.valuta}
          </div>
          <div className="Locations-Container-Results-ListBlock-Cell">
            {filter.phone}
          </div>
          <div className="Locations-Container-Results-ListBlock-Cell">
            {filter.worktime}
          </div>
        </div>
      </div>
      {branches.map((branch) => (
        <div
          className="Locations-Container-Results-ListBlock-Row Locations-Container-Results-ListBlock-Point"
          key={branch.address}
        >
          <div className="Locations-Container-Results-ListBlock-Cell">
            {branch.label}
            <div className="Locations-Container-Results-ListBlock-Cell-Comment">
              {branch.address}
            </div>
          </div>
          <div className="Locations-Container-Results-ListBlock-Cell">
            {`${filter[aliasVariant]}: ${branch.currencies.join(',')}`}
          </div>
          <div className="Locations-Container-Results-ListBlock-Cell">
            <a href={`tel:${branch.phone}`}>{branch.phone}</a>
          </div>
          <div
            className="Locations-Container-Results-ListBlock-Cell"
            dangerouslySetInnerHTML={{
              __html:
                branch.workSchedule.from +
                ' - ' +
                branch.workSchedule.to +
                '<br />' +
                branch.workSchedule.comment,
            }}
          />
        </div>
      ))}
    </>
  );
};

export default TablePointsList;
