import { useTranslation } from 'react-i18next';

import { Documents } from '../components/Documents';
import { PageContent } from '../components/PageContent';
import SEO from '../components/seo';

const DocumentsPage = () => {
  const { t, i18n } = useTranslation(['documents_page']);

  const { groups, ...texts } = t('documents');

  return (
    <PageContent>
      <SEO seo={t('seo')} locale={i18n.language} />

      <Documents groups={groups} locale={i18n.language} texts={texts} />
    </PageContent>
  );
};

export default DocumentsPage;
