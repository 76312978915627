export const langs = [
  {
    code: 'en',
  },
  {
    code: 'ru',
  },
  {
    code: 'tr',
  },
];
