import PropTypes from 'prop-types';

import styles from './NewsCurrentContainer.module.css';

export const NewsCurrentContainer = ({ children }) => (
  <div className={styles.root}>{children}</div>
);

NewsCurrentContainer.propTypes = {
  children: PropTypes.node.isRequired,
};
