import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import mobileFooterLogo from '../static/mobileFooterLogo.svg';

const currentYear = new Date().getFullYear();

const Footer = () => {
  const { i18n, t } = useTranslation(['common']);

  const basePath = `/${i18n.resolvedLanguage}`;

  return (
    <footer className="footer">
      <div className="Block-Container Block-Container-Centered">
        <div className="Block-Container-Top">
          <div className="Block-Container-Top-Logo">
            <img
              className="Block-Container-Top-Logo-Image"
              alt="dengi.ru"
              src={mobileFooterLogo}
            />
          </div>

          <div className="Block-Container-Top-Menu">
            <ul>
              <li className="Navbar-Container-Menu-Item">
                <Link to={`${basePath}/transfer/find-locations`}>
                  {t('navbar.points')}
                </Link>
              </li>

              <li className="Navbar-Container-Menu-Item">
                <Link to={`${basePath}/company/partners`}>
                  {t('navbar.partners')}
                </Link>
              </li>

              <li className="Navbar-Container-Menu-Item">
                <Link to={`${basePath}/company/legal`}>
                  {t('navbar.legal')}
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <div className="footer-delimeter"></div>
        <div className="footer-legalNotice">{t('footer.legalNotice')}</div>
        <div className="footer-infoNotice">{t('footer.infoNotice')}</div>
        <div className="footer-copyright">
          {t('footer.copyright', { currentYear })}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
