import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  PageTitle,
  NewsContainer,
  NewsButton,
  NewsList,
  NewsPageContainer,
  YearSwitcher,
} from '../components/News';
import SEO from '../components/seo';
import { getList, getListYears } from '../models/News';

const News = () => {
  const { t, i18n } = useTranslation(['news_page']);

  const [selectedYear, setYear] = useState(null);
  const [yearsAliasesList, setYearsAliasesList] = useState([]);

  const [news, setNews] = useState({
    data: [],
    meta: {},
  });

  const yearsOptions = useMemo(
    () =>
      yearsAliasesList.map((yearData) => ({
        label: yearData?.year,
        value: yearData?.year,
      })),
    [yearsAliasesList],
  );

  useEffect(() => {
    const loadYearsAliases = async () => {
      const { data: yearsAliasesData } = await getListYears({
        lang: i18n.language,
      });

      if (yearsAliasesData?.length) {
        setYearsAliasesList(yearsAliasesData);

        const years = yearsAliasesData.map((yearNews) => yearNews.year);

        const year = years[years.length - 1];

        if (!year) {
          return null;
        }

        setYear(year);
      }
    };

    loadYearsAliases();
  }, [i18n.language]);

  useEffect(() => {
    const load = async () => {
      if (!selectedYear) {
        return null;
      }

      const newsData = await getList(
        { lang: i18n.language, year: selectedYear },
        { pagination: { page: 1 } },
      );

      if (newsData) {
        setNews(newsData);
      }
    };

    load();
  }, [selectedYear, i18n.language]);

  const showMore = async () => {
    if (!selectedYear) {
      return null;
    }

    const newNewsData = await getList(
      { lang: i18n.language, year: selectedYear },
      { pagination: { page: news?.meta?.page + 1 } },
    );

    if (newNewsData) {
      setNews((newsData) => ({
        data: [...newsData.data, ...newNewsData.data],
        meta: newNewsData.meta,
      }));
    }
  };

  return (
    <>
      <SEO seo={t('seo')} locale={i18n.language} />

      <NewsPageContainer>
        <PageTitle
          title={t('newsPage.title')}
          description={t('newsPage.description')}
        />

        {selectedYear && (
          <YearSwitcher
            items={yearsOptions}
            onSwitch={setYear}
            selectedYear={selectedYear}
          />
        )}

        <NewsContainer>
          <NewsList
            newsList={news?.data}
            locale={{ t, i18n }}
            year={selectedYear}
          />

          {news?.meta?.page < news?.meta?.pagesCount && (
            <NewsButton onClick={showMore}>{t('newsPage.showMore')}</NewsButton>
          )}
        </NewsContainer>
      </NewsPageContainer>
    </>
  );
};

export default News;
