import classNames from 'classnames';
import PropTypes from 'prop-types';

import { ReactComponent as DocumentIcon } from '../../icons/document.svg';
import { ReactComponent as DownloadIcon } from '../../icons/download.svg';

import styles from './DownloadLink.module.css';

export const DownloadLink = (props) => {
  const { children, href, variant = 'main' } = props;

  return (
    <a
      className={classNames(styles.root, styles[`variant-${variant}`])}
      href={href}
      rel="noopener noreferrer"
      target="_blank"
    >
      <DocumentIcon className={styles.iconDocument} />

      <span className={styles.label}>{children}</span>

      <DownloadIcon className={styles.iconDownload} />
    </a>
  );
};

DownloadLink.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired,

  variant: PropTypes.oneOf(['archive', 'main']),
};
