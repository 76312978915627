export const checkElementInViewport = ($element, options = {}) => {
  const { isPartiallyVisible = false } = options;

  const { top, left, bottom, right } = $element.getBoundingClientRect();
  const { innerHeight, innerWidth } = window;

  if (isPartiallyVisible) {
    return (
      ((top > 0 && top < innerHeight) ||
        (bottom > 0 && bottom < innerHeight)) &&
      ((left > 0 && left < innerWidth) || (right > 0 && right < innerWidth))
    );
  }

  return top >= 0 && left >= 0 && bottom <= innerHeight && right <= innerWidth;
};
