import { ReactComponent as AlertIcon } from '../../icons/alert.svg';
import { ReactComponent as CloseIcon } from '../../icons/close.svg';

import styles from './styles.module.css';

export const Banner = ({ banner, onClose }) => {
  return (
    <div className={styles.banner}>
      <div className={styles.alert}>
        <AlertIcon className={styles.icon} />
      </div>

      <div className={styles.text}>
        <a
          href={banner.link}
          className={styles.link}
          rel="noopener noreferrer"
          target="_blank"
        >
          {banner.text}
        </a>
      </div>

      <button onClick={onClose} className={styles.close}>
        <CloseIcon className={styles.icon} />
      </button>
    </div>
  );
};
