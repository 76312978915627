import PathToRegexp, { compile } from 'path-to-regexp';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { CountryItemToggler } from './CountryItemToggler';

import '../styles/blocks/language.scss';

export default function SwitchLang() {
  const { t, i18n } = useTranslation();

  const [isShownLangPanel, setIsShownLangPanel] = useState(false);

  const generateLanguage = (locale, location) => {
    const ROUTE = '/:locale/:path*';
    const definePath = compile(ROUTE);
    const routeComponents = PathToRegexp(ROUTE).exec(location.pathname);

    let subPaths = null;

    if (routeComponents && routeComponents[2]) {
      subPaths = routeComponents[2].split('/');
    }

    return definePath({
      locale,
      path: subPaths,
    });
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setIsShownLangPanel((current) => !current);
  };

  const handleClick = () => {
    setIsShownLangPanel((current) => !current);
  };

  const locales = t('locales');

  return (
    <div className="ChangeLanguage-Panel">
      <CountryItemToggler
        code={i18n.resolvedLanguage}
        onClick={handleClick}
        isIconToggled={isShownLangPanel}
      />

      {isShownLangPanel ? (
        <div className="ChangeLanguage-Panel-Popup">
          {locales.map((item) => {
            const isSelected = i18n.resolvedLanguage === item.code;

            return (
              <Link
                className="ChangeLanguage-Panel-Popup-Item"
                key={item.code}
                to={generateLanguage(item.code, location)}
              >
                <button
                  onClick={() => changeLanguage(item.code)}
                  className={`ChangeLanguage-Item ${
                    isSelected ? 'ChangeLanguage-Item-Active' : ''
                  }`}
                  disabled={isSelected}
                >
                  {item.name}
                </button>
              </Link>
            );
          })}
        </div>
      ) : null}
    </div>
  );
}
