import classNames from 'classnames';
import PropTypes from 'prop-types';

import { ReactComponent as SmallCaretDownIcon } from '../../icons/small-caret-down.svg';

import styles from './ArchiveRevealer.module.css';

export const ArchiveRevealer = (props) => {
  const { children, isRevealed = false, ...restProps } = props;

  return (
    <button
      {...restProps}
      className={classNames(styles.root, { [styles.isRevealed]: isRevealed })}
      type="button"
    >
      {children}

      <SmallCaretDownIcon className={styles.icon} />
    </button>
  );
};

ArchiveRevealer.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,

  isRevealed: PropTypes.bool,
};
