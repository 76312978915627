import { useEffect, useRef } from 'react';

/**
 * React-хук, хранящий предыдущее значение.
 *
 * @param {any} [value] - Текущее значение.
 * @returns {any} - Предыдущее значение.
 */
export const usePreviousValue = (value) => {
  const prevValue = useRef();

  useEffect(() => {
    prevValue.current = value;
  }, [value]);

  return prevValue.current;
};
