import '../styles/blocks/blockToMap.scss';
import Button from '../components/button';

const BlockToMap = ({ content }) => {
  return (
    <div className="BlockToMap">
      <div className="BlockToMap-Container Block-Container-Centered ">
        <div className="BlockToMap-Title">{content.title}</div>
        <div className="BlockToMap-Text">{content.text}</div>
        <div className="BlockToMap-Button">
          <Button title={content.button.title} link={content.button.link} />
        </div>
      </div>
    </div>
  );
};

export default BlockToMap;
