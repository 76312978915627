import PropTypes from 'prop-types';

import styles from './NewsContainer.module.css';

export const NewsContainer = ({ children }) => (
  <div className={styles.root}>{children}</div>
);

NewsContainer.propTypes = {
  children: PropTypes.node.isRequired,
};
