import Select from 'react-select';

import '../styles/components/selectField.scss';

const SelectField = (props) => {
  const { onChange, options, value, withIcon = false } = props;

  const selectedOption = options.find((option) => option.value === value) ?? {};

  return (
    <Select
      className="Select-Container"
      classNamePrefix="Select-Box"
      classNames={{
        option: (state) => {
          return state.data.value === value
            ? 'Select-Box__option-selected'
            : 'Select-Box__option';
        },
      }}
      components={{
        IndicatorSeparator: () => null,
      }}
      getOptionLabel={(option) => {
        return (
          <div
            style={{ display: 'flex', alignItems: 'center' }}
            className="Select-Box-Option-Icon"
          >
            {withIcon ? (
              <img
                src={require(`../static/icons/country/${option.value}.svg`)}
                style={{ width: '20px' }}
              />
            ) : null}

            <span style={{ marginLeft: 5 }}>{option.label}</span>
          </div>
        );
      }}
      onChange={(option) => onChange(option.value)}
      options={options}
      placeholder={null}
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          boxShadow: state.isFocused ? 'inset 0px 0px 0px 4px #c3d0d7' : '',
        }),
        option: (baseStyles) => ({
          ...baseStyles,
          color: '#0b1a12',
          backgroundColor: '#fff',
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          flexWrap: 'nowrap',
          flexDirection: 'row',
          fontSize: '14px',
          fontWeight: 400,
          lineHeight: '18px',

          ':hover': {
            ...baseStyles[':hover'],
            backgroundColor: '#d7e0e5',
          },
        }),
      }}
      value={selectedOption}
    />
  );
};

export default SelectField;
