import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { dateParse } from '../../models/News';

import styles from './NewsList.module.css';

export const NewsList = ({ language, newsList }) => (
  <div className={styles.root}>
    {newsList.map((news) => {
      return (
        <Link
          className={styles.item}
          key={news.alias}
          to={`/${language}/news/${
            dateParse(news.createdAt, language)?.fullYear
          }/${news.alias}`}
        >
          {news.imageUrl ? (
            <div className={styles.imageBox}>
              <img
                alt={news.title}
                className={styles.image}
                src={news.imageUrl}
              />
            </div>
          ) : null}

          <div className={styles.newsBox}>
            <h3 className={styles.title}>{news.title}</h3>
          </div>
        </Link>
      );
    })}
  </div>
);

NewsList.propTypes = {
  language: PropTypes.string,
  newsList: PropTypes.array,
};
