import { useEffect, useState } from 'react';

/**
 * React-хук, возвращающий текущую ширину экрана.
 *
 * @returns {number} - Ширина экрана в px.
 */

export const useResize = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = (event) => {
      setWidth(event.target.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return width;
};
