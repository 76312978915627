import axios from 'axios';

/**
 * Возвращает списки пунктов, сгруппированные по странам и городам.
 *
 * @param {Object} params
 * @param {string} params.lang
 * @param {string} params.type
 * @returns {{ data: {
 *   {
 *     option: string,
 *     title: string,
 *   }[]
 * } }}
 *
 * @example
 *
 * const { data } = await getListByType({
 *   lang: 'ru',
 *   type: 'send',
 * });
 */

export const getListByType = async (params) => {
  const { lang, type } = params;

  try {
    const { data } = await axios.get(
      `/data/service-branch/${lang}/${type}.json`,
    );

    return data;
  } catch {
    return Promise.reject('NOT_FOUND');
  }
};
