import PropTypes from 'prop-types';
import * as React from 'react';

import { Box } from '../../components/Box';
import { Reveal } from '../../components/Reveal';

import styles from './Archive.module.css';
import { ArchiveRevealer } from './ArchiveRevealer';
import { DownloadLink } from './DownloadLink';
import { PublicationDate } from './PublicationDate';
import { documentPropTypes } from './propTypes';

const DEFAULT_IS_REVEALED = false;

export const Archive = (props) => {
  const { documents, locale, texts } = props;

  const [isRevealed, setIsRevealed] = React.useState(DEFAULT_IS_REVEALED);

  return (
    <>
      <h2 className={styles.title}>
        {texts.archive}

        <ArchiveRevealer
          isRevealed={isRevealed}
          onClick={() =>
            setIsRevealed((isCurrentRevealed) => !isCurrentRevealed)
          }
        >
          {isRevealed ? texts['archive_hide'] : texts['archive_show']}
        </ArchiveRevealer>
      </h2>

      <Reveal isRevealed={isRevealed}>
        <ul className={styles.list}>
          {documents.map((document) => {
            return (
              <Box className={styles.item} key={document.link} tag="li">
                <PublicationDate
                  classNames={{
                    date: styles.dateDate,
                    root: styles.dateRoot,
                  }}
                  date={document.publicationDate}
                  label={texts['publication_date']}
                  locale={locale}
                />

                <DownloadLink href={document.link} variant="archive">
                  {document.label}
                </DownloadLink>
              </Box>
            );
          })}
        </ul>
      </Reveal>
    </>
  );
};

Archive.propTypes = {
  documents: PropTypes.arrayOf(documentPropTypes).isRequired,
  locale: PropTypes.string.isRequired,
  texts: PropTypes.objectOf(PropTypes.string).isRequired,
};
