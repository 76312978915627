import { createFilter } from '../utils/createFilter';

import { getListByYear } from './getListByYear';

/**
 * Возвращает новость в конкретном году по ее alias.
 *
 * @param {Object} params
 * @param {string} params.alias
 * @param {string} params.lang
 * @param {string} params.year
 * @returns {{ data: News }}
 *
 * @example
 *
 * const { data } = await getItem({
 *   alias: 'some-good-news',
 *   lang: 'ru',
 *   year: '2023',
 * });
 */
export const getItem = async (params) => {
  const { alias, lang, year } = params;

  const filter = createFilter({
    alias: (value) => value === alias,
  });
  const { data } = filter(
    await getListByYear({
      lang,
      year,
    }),
  );

  if (data.length === 0) {
    return Promise.reject('NOT_FOUND');
  }

  if (data.length > 1) {
    return Promise.reject('COLLISION_DETECTED');
  }

  return { data: data[0] };
};
