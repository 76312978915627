import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import Switcher from '../components/switcher';
import SwitcherContent from '../components/switcherContent';

import '../styles/blocks/switchContent.scss';

const SwitchContent = (props) => {
  const { data } = props;

  const ref = useRef(null);

  const switchVariants = useMemo(() => {
    return [
      {
        option: 'send',
        title: data.send.btnTitle,
      },
      {
        option: 'receive',
        title: data.receive.btnTitle,
      },
    ];
  }, [data.receive.btnTitle, data.send.btnTitle]);

  const history = useHistory();
  const location = useLocation();

  const optionFromUrl = useMemo(() => {
    const hash = location.hash.slice(1);

    if (switchVariants.some(({ option }) => option === hash)) {
      return hash;
    }

    return null;
  }, [location, switchVariants]);

  useEffect(() => {
    const { shouldScroll = false } = location.state ?? {};

    if (optionFromUrl && shouldScroll) {
      ref.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location.state, optionFromUrl]);

  const selectedOptionName = useMemo(() => {
    return optionFromUrl || switchVariants[0].option;
  }, [optionFromUrl, switchVariants]);

  const handleSwitch = useCallback(
    (optionName) => {
      history.push({
        hash: optionName,
        pathname: location.pathname,
      });
    },
    [history, location.pathname],
  );

  return (
    <div className="Block" ref={ref}>
      <h3 className="SwitchContent-Title">{data.title}</h3>

      <div className="Block-Container Block-Container-Centered SwitchContent">
        <div className="SwitchContent-Container">
          <div className="SwitchContent-Switcher">
            <Switcher
              className="SwitchContent-Switcher-Block"
              currentAlias={selectedOptionName}
              handleFunction={handleSwitch}
              items={switchVariants}
            />
          </div>

          <div className="SwitchContent-SlideContent">
            <SwitcherContent item={data[selectedOptionName]} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SwitchContent;
