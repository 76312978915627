import { Popup } from '../Popup';

import styles from './styles.module.css';

export const Modal = (props) => {
  const { action, children } = props;

  return (
    <Popup>
      <div className={styles.root}>
        <div className={styles.content}>{children}</div>

        <button
          onClick={action.onClick}
          type={action.type}
          className={styles.action}
        >
          {action.label}
        </button>
      </div>
    </Popup>
  );
};
