import CountryItem from '../components/countryItem';

import '../styles/blocks/countryBlock.scss';

const CountryBlock = (props) => {
  const { countries } = props;

  return (
    <div className="Block CountryBlock">
      <div className="Block-Container Block-Container-Centered CountryBlock-Container">
        <h2 className="CountryBlock-Title">{countries.title}</h2>

        <div className="CountryBlock-Items">
          {countries.items.map((item) => (
            <CountryItem code={item.code} key={item.code} soon={item.soon} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default CountryBlock;
