import IconArrowBottom from '../../static/icons/arrow-bottom.svg';

export const CountryItemToggler = ({ code, onClick, isIconToggled }) => {
  return (
    <div className="CountryBlock-Items-Country">
      <div className="CountryBlock-Items-Country-Header" onClick={onClick}>
        <div className="CountryBlock-Items-Country-Header-Toggler">
          <span className="CountryBlock-Items-Country-Header-Toggler-Language">
            {code}
          </span>
          <img
            alt="arrow"
            className={`CountryBlock-Items-Country-Header-Toggler-Icon ${
              isIconToggled &&
              'CountryBlock-Items-Country-Header-Toggler-Icon-Opened'
            }`}
            src={IconArrowBottom}
          />
        </div>
      </div>
    </div>
  );
};
