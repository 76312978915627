/**
 * Возвращает отсартированные данные по дате в зависимости от переданного параметра сортировки ASK(по возрастанию) | DESK (по убыванию).
 *
 * @param {Object} data
 * @param {string | undefined} sort
 * @returns {Object}
 */
export const sortByDate = (data, sort) =>
  data.sort((a, b) => {
    const dateA = new Date(a.createdAt);
    const dateB = new Date(b.createdAt);

    switch (sort) {
      case 'DESK':
        return dateB - dateA;
      case 'ASK':
        return dateA - dateB;
      default:
        return 0;
    }
  });
