import ContactsItem from '../components/contactsItem';

const Contacts = ({ items }) => {
  return (
    <>
      {items.map((item, index) => (
        <ContactsItem key={index} index={index} item={item} />
      ))}
    </>
  );
};

export default Contacts;
