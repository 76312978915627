import PropTypes from 'prop-types';
import * as React from 'react';
import { Link } from 'react-router-dom';

import styles from './NewsButton.module.css';

export const NewsButton = (props) => {
  const { children, onClick, to } = props;

  const Root = React.useMemo(() => {
    return (rootProps) => {
      if (to) {
        return <Link {...rootProps} to={to} />;
      }

      return <button {...rootProps} onClick={onClick} type="button" />;
    };
  }, [onClick, to]);

  return <Root className={styles.root}>{children}</Root>;
};

NewsButton.propTypes = {
  onClick: PropTypes.func,
  to: PropTypes.string,
};
