import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { dateParse } from '../../models/News';

import { CurrentNewsButton } from './CurrentNewsButton';
import styles from './OtherNews.module.css';

export const OtherNews = (props) => {
  const { allNewsLink, locale, newsList } = props;

  const { t, i18n } = useTranslation(['news_page']);

  const newsDateParse = (date) => {
    const { day, monthName, fullYear } = dateParse(date, locale);

    return `${monthName} ${day}, ${fullYear}`;
  };

  if (!newsList?.length) {
    return null;
  }

  return (
    <div className={styles.root}>
      <h2 className={styles.title}>{t('newsPage.otherNews')}</h2>

      <ul className={styles.list}>
        {newsList.map((news) => {
          return (
            <li className={styles.item} key={news.alias}>
              <div className={styles.date}>{newsDateParse(news.createdAt)}</div>

              <div className={styles.itemTitle}>
                <Link
                  className={styles.itemLink}
                  to={`/${i18n.language}/news/${
                    dateParse(news.createdAt).fullYear
                  }/${news.alias}`}
                >
                  {news.title}
                </Link>
              </div>
            </li>
          );
        })}
      </ul>

      <CurrentNewsButton to={allNewsLink}>
        {t('newsPage.allNews')}
      </CurrentNewsButton>
    </div>
  );
};

OtherNews.propTypes = {
  allNewsLink: PropTypes.string.isRequired,

  newsList: PropTypes.array,
};
