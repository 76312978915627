const TablePointsList = ({ aliasVariant, branches, filter }) => {
  return (
    <>
      {branches.map((branch) => (
        <div
          className="Locations-Container-Results-Mobile-ListBlock"
          key={branch.address}
        >
          <div className="Locations-Container-Results-Mobile-ListBlock-Item">
            <div className="Locations-Container-Results-Mobile-ListBlock-Item-Header">
              {filter.branch}
            </div>
            {branch.label}
            <div className="Locations-Container-Results-Mobile-ListBlock-Item-Comment">
              {branch.address}
            </div>
          </div>

          <div className="Locations-Container-Results-Mobile-ListBlock-Item">
            <div className="Locations-Container-Results-Mobile-ListBlock-Item-Header">
              {filter.valuta}
            </div>
            {`${filter[aliasVariant]}: ${branch.currencies.join(',')}`}
          </div>

          <div className="Locations-Container-Results-Mobile-ListBlock-Item">
            <div className="Locations-Container-Results-Mobile-ListBlock-Item-Header">
              {filter.phone}
            </div>
            <a
              className="Locations-Container-Results-Mobile-ListBlock-Item-Phone"
              href={`tel:${branch.phone}`}
            >
              {branch.phone}
            </a>
          </div>

          <div className="Locations-Container-Results-Mobile-ListBlock-Item">
            <div className="Locations-Container-Results-Mobile-ListBlock-Item-Header">
              {filter.worktime}
            </div>
            {branch.workSchedule.from + ' - ' + branch.workSchedule.to}
            <br />
            {branch.workSchedule.comment}
          </div>
        </div>
      ))}
    </>
  );
};

export default TablePointsList;
