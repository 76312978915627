import styles from './styles.module.css';

export const Loader = () => {
  return (
    <div className={styles.root} role="img">
      <div className={styles.container}>
        <div className={styles.spinner} />
      </div>
    </div>
  );
};
