/**
 * Возвращает отсартированные данные в зависимости от переданных параметров ключа key по каторому будем сортировать и sort правило для сортировки ASK(по возрастанию) | DESK (по убыванию).
 *
 * @param {Object} data
 * @param {string} key
 * @param {string | undefined} sort
 * @returns {Object}
 */
export const sortByKey = (data, key, sort) =>
  data.sort((a, b) => {
    switch (sort) {
      case 'DESK':
        return b[key] - a[key];
      case 'ASK':
        return a[key] - b[key];
      default:
        return 0;
    }
  });
