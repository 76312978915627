import classNames from 'classnames';
import PropTypes from 'prop-types';
import * as React from 'react';

import { Reveal } from '../../components/Reveal';
import { ReactComponent as SmallCaretDownIcon } from '../../icons/small-caret-down.svg';

import styles from './Accordion.module.css';

const DEFAULT_OPENED_INDEXES_LIST = [0];

const invertItem = (item) => {
  return (list) => {
    if (list.includes(item)) {
      return list.filter((listItem) => listItem !== item);
    }

    return [...list, item];
  };
};

export const Accordion = (props) => {
  const { items } = props;

  const [openedIndexesList, setOpenedIndexesList] = React.useState(
    DEFAULT_OPENED_INDEXES_LIST,
  );

  const handleToggle = (index) => {
    setOpenedIndexesList(invertItem(index));
  };

  return (
    <div className={styles.root}>
      {items.map((item, index) => {
        const isActive = openedIndexesList.includes(index);

        return (
          <div
            className={classNames(styles.item, { [styles.isActive]: isActive })}
            key={item.title}
          >
            <button
              className={styles.revealer}
              onClick={() => handleToggle(index)}
              type="button"
            >
              {item.title}

              <SmallCaretDownIcon className={styles.icon} />
            </button>

            <Reveal isRevealed={isActive}>
              <div className={styles.content}>{item.content}</div>
            </Reveal>
          </div>
        );
      })}
    </div>
  );
};

Accordion.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      content: PropTypes.node.isRequired,
    }),
  ).isRequired,
};
